import React from "react"
import Button from "shared/components/Button"
import Modal from "shared/components/Modal"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import { newAdminCampaignPath } from "mill/routes/RouteNames"
import { Title } from "./styles"

const CampaignMechanismModal = ({ modalOpen, closeModal }) => {
  return (
    <Modal
      open={modalOpen}
      closeModal={closeModal}
      showCloseIcon
      maxWidth={"90rem"}>
      <Box paddingBottom="medium">
        <Heading level={2}>
          What type of campaign would you like to create?
        </Heading>
        <Box display={{ mobile: "none", tablet: "block" }}>
          <Text>
            To determine the most suitable campaign type, it’s best to consider
            the goals of this campaign.
          </Text>
        </Box>
      </Box>

      <Box display="flex" flexDirection={{ mobile: "column", tablet: "row" }}>
        <Box
          flex="1"
          paddingBottom={{ mobile: "medium", tablet: "none" }}
          paddingRight={{ tablet: "medium" }}>
          <Title level={3}>Embed Campaign</Title>
          <Box paddingTop="small" paddingBottom="small">
            <Text>
              Designed to embed knowledge over a period of time, usually 3 - 8
              weeks. These campaigns utilise microlearning and spaced
              repetition.
            </Text>
          </Box>
          <Button
            data-qa="new-embed-campaign"
            color="primary"
            to={newAdminCampaignPath("embed")}
            label={"Choose"}
          />
        </Box>

        <Box flex="1" paddingLeft={{ tablet: "medium" }}>
          <Title level={3} burst>
            Burst Campaign
          </Title>
          <Box paddingTop="small" paddingBottom="small">
            <Text>
              Designed to communicate or test knowledge quickly and efficiently
              by delivering all questions in one go. These campaigns are
              typically 5 to 10 questions long and can be completed in a few
              minutes.
            </Text>
          </Box>

          <Button
            data-qa="new-burst-campaign"
            color="primary"
            to={newAdminCampaignPath("burst")}
            label={"Choose"}
          />
        </Box>
      </Box>
    </Modal>
  )
}
export default CampaignMechanismModal
