import React from "react"
import styled from "styled-components"
import colors from "shared/utils/colors"
import { lighten } from "polished"

import Tooltip from "mill/components/Tooltip"
import tooltipImg from "mill/components/Tooltip/info-hover.png"

const ActiveSessionsContainer = styled.div`
  padding-bottom: 1rem;
  max-width: 33rem;

  p {
    margin: 0;
    color: ${colors.grayAluminium};
    font-size: 1.3rem;
  }
`

const ActiveSessions = styled.div`
  background: ${lighten(0.04, colors.grayWhisper)};
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 0.6rem;
  padding-top: 1rem;

  > div {
    flex-grow: 1;
  }
`

const ActiveSessionsBar = styled.div`
  background: ${props => props.theme.colors.primary};
  width: 0.9rem;
  align-self: flex-end;
  border-radius: 0.2rem 0.2rem 0 0;
  margin-right: 0.2rem;
  width: 100%;
`

const ActiveSessionsLabel = styled.p`
  display: inline-block;
  padding-right: 0.6rem;
  vertical-align: middle;
`

export const Icon = styled.span`
  border-radius: 0.3rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: ${colors.grayAthens};
  background-image: url(${tooltipImg});
  background-position: center center;
  background-size: 0.4rem 0.9rem;
  vertical-align: middle;
`

const QuickView = ({ campaignData }) => {
  const {
    dailySessionCounts: campaignSessions,
  } = campaignData

  const renderActiveSessionBars = () => {
    if (campaignSessions.length === 0) return null
    let maxSessions = campaignSessions
      .map(day => day.value)
      .reduce((prev, current) => {
        return prev > current ? prev : current
      })
    return (
      campaignSessions &&
      campaignSessions.map(day => {
        const height = (day.value / maxSessions) * 100
        const date = day.label
        return (
          <Tooltip
            hoverText={`${day.value} sessions on ${date}`}
            flex
            key={day.label}>
            <ActiveSessionsBar
              key={day.label}
              style={{ height: `${height}%` }}
            />
          </Tooltip>
        )
      })
    )
  }

  return (
    <ActiveSessionsContainer>
      <ActiveSessions>{renderActiveSessionBars()}</ActiveSessions>
      <Tooltip
        hoverText={
          "The number of learners who answered questions on a single day"
        }>
        <ActiveSessionsLabel>Active sessions</ActiveSessionsLabel>
        <Icon />
      </Tooltip>
    </ActiveSessionsContainer>
  )
}

export default QuickView
