import styled from "styled-components"
import { darken } from "polished"
import colors from "shared/utils/colors"
import { md, lg } from "mill/utils/breakpoints"
import Box from "shared/components/Box"

export const Drawer = styled(Box)`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  > div {
    padding-left: 47px;
    @media ${md} {
      padding-left: 0;
    }
  }
  ${({ drawerOpen }) =>
    drawerOpen &&
    `
    border-top: 1px dashed #e9e9e9;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  `}

  @media ${md} {
    padding-left: 4.7rem;
  }
`

export const Arrow = styled.i`
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.7rem;
  margin-top: -0.6rem;
  padding: 1rem;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  cursor: pointer;
  &:hover {
    color: ${props => darken(0.1, props.theme.colors.primary)};
  }
  ${props => props.isExpanded && `transform: scaleY(-1);`}
`

export const CampaignDetailsSection = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  ${({ desktopOnly }) => desktopOnly && `display: none;`}
  ${props =>
    props.borderRight && `border-bottom: 1px dashed ${colors.graySolitude};`}

  @media ${md} {
    display: block;
  }

  @media ${lg} {
    border-bottom: none;
    ${props =>
      props.borderRight &&
      `
      border-right: 1px dashed ${colors.graySolitude};
    `}
  }
`
