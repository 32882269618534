import React, { Fragment } from "react"
import styled from "styled-components"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import { useQuery } from "@apollo/client"
import CampaignMechanismModal from "mill/components/CampaignMechanismModal"
import Wrapper from "shared/components/Wrapper"
import FilterBar from "shared/components/FilterBar"
import SearchSelector from "shared/containers/SearchSelector"
import DateRangeSelector from "mill/containers/DateRangeSelector"
import CampaignCollectionSelector from "mill/containers/CampaignCollectionSelector"
import CampaignStateSelector from "mill/containers/CampaignStateSelector"
import SortBySelector from "shared/containers/SortBySelector"
import Box from "shared/components/Box"
import Button from "shared/components/Button"
import EmptyState from "mill/components/EmptyState"
import CampaignsList from "mill/containers/Admin/CampaignsList"
import useTrackPage from "mill/hooks/useTrackPage"
import usePageTitle from "mill/hooks/usePageTitle"
import useModal from "shared/hooks/useModal"
import { md, lg, xl } from "mill/utils/breakpoints"
import TotalCountDisplay from "shared/components/TotalCountDisplay"
import { useFilterStateReducer } from "shared/hooks/useSelector"

import FETCH_ACCOUNT_CAMPAIGN_COUNT from "mill/graphql/FetchAccountCampaignCount"

const sortOptions = [
  { label: "Alphabetical (A→Z)", value: "name asc" },
  { label: "Alphabetical (Z→A)", value: "name desc" },
  { label: "Start date earliest to latest", value: "startDate asc" },
  {
    label: "Start date latest to earliest",
    value: "startDate desc",
    default: true
  },
  { label: "End date earliest to latest", value: "endDate asc" },
  { label: "End date latest to earliest", value: "endDate desc" }
]

const CampaignFilterGrid = styled.div`
  @media ${md} {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 3rem;
  }
  @media ${lg} {
    grid-template-columns: 48rem 1fr;
    > div:nth-of-type(1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    > div:nth-of-type(2) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  @media ${xl} {
    grid-template-columns: 4fr 48rem 2fr;
    > div:nth-of-type(1) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    > div:nth-of-type(2) {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
`

const CampaignDashboard = () => {
  const { modalOpen, toggleModal } = useModal()
  const { data, error } = useQuery(FETCH_ACCOUNT_CAMPAIGN_COUNT)

  useTrackPage("Admin Campaigns")
  usePageTitle("Campaigns")

  const initiallyShowFilterBar = useFilterStateReducer([
    "activeStart",
    "activeEnd",
    "states",
    "collectionIds"
  ])

  if (error) return <div>Error :(</div>

  const account = data && data.viewer && data.viewer.account
  const hasCampaigns = account && account.totalCampaignCount > 0

  return (
    <Fragment>
      <CampaignMechanismModal modalOpen={modalOpen} closeModal={toggleModal} />

      <Wrapper>
        <Box
          display={{ mobile: "block", tablet: "flex" }}
          justifyContent="space-between"
          alignItems="center"
          paddingTop="large"
          paddingBottom="large">
          <Box marginBottom={{ mobile: "large", tablet: "none" }}>
            <Heading level={1}>
              Campaigns
              <TotalCountDisplay
                count={account && account.totalCampaignCount}
                model="campaign"
              />
            </Heading>
          </Box>
          <Button
            data-qa="new-campaign"
            color="secondary"
            onClick={toggleModal}
            label="New campaign"
          />
        </Box>

        {!account ? null : hasCampaigns ? (
          <Fragment>
            <FilterBar
              initiallyOpen={initiallyShowFilterBar}
              visibleComponent={
                <Fragment>
                  <SearchSelector
                    autoFocus={true}
                    placeholder={"Find a campaign"}
                  />
                  <SortBySelector options={sortOptions} />
                </Fragment>
              }>
              <CampaignFilterGrid>
                <DateRangeSelector />
                <CampaignStateSelector />
                <CampaignCollectionSelector />
              </CampaignFilterGrid>
            </FilterBar>
            <CampaignsList />
          </Fragment>
        ) : (
          <EmptyState imagePath="static/empty-state/campaigns.png?auto=format,compress">
            <Box paddingTop="xsmall" paddingBottom="xsmall">
              <Heading level={2}>Launch your first campaign</Heading>
            </Box>
            <Box paddingBottom="small">
              <Text tone="dark">
                Looks like you don't have any campaigns yet. Don't worry,
                creating one's easy!
              </Text>
            </Box>
            <Button
              data-qa="new-campaign-empty-state"
              color="secondary"
              onClick={toggleModal}
              label="New campaign"
            />
          </EmptyState>
        )}
      </Wrapper>
    </Fragment>
  )
}

export default CampaignDashboard
